@import "../variables";

#page-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: $primary;

    [dir="rtl"] & {
        right: 0;
        left: initial;
    }
}

.page-loader .fa {
    font-size: 2.5rem;
}

.page-loader,
.page-loader.visible {
    transition: 1.2s ease-in;
    opacity: 1;
    visibility: visible;
}

.page-loader.p-hidden {
    visibility: hidden;
    opacity: 0;
}

.page-loader div {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    margin-top: 0;
    display: block;
    transform: translateY(-50%);
}

.page-loader div .icon {
    display: inline-block;
    margin-left: -40px;
    [dir="rtl"] & {
        margin-right: -40px;
        margin-left: initial;
    }
    margin-top: -40px;
    left: 50%;
    [dir="rtl"] & {
        right: 50%;
        left: initial;
    }
    width: 80px;
    height: 80px;
    content: "";
    background: transparent;
    border-radius: 100px;
    border: 2px solid $secondary;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.page-loader div p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.2em;
    color: $secondary;
    text-transform: uppercase;
    font-weight: bold;
    //letter-spacing: 1em #{"/* rtl:0px; */"};
    //padding-left: 1em;

    [dir="ltr"] & {
        letter-spacing: 1em;
        padding-left: 1em;
    }
}

.icon-spin,
.icon-loading-a {
    animation: spin 1s infinite linear;

    [dir="ltr"] & {
        animation: spinRtl 1s infinite linear;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes spinRtl {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-359deg);
    }
}
