@import "../variables";

.inline-separator {
    display: inline-flex;
    width: 1em;
    height: 1em;
    position: relative;
    content: "";
    vertical-align: middle;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        width: 100%;
        height: auto;
        border-bottom: 2px solid currentColor;
    }
}

hr.mission {
    border: .5px dashed $primary;
}
