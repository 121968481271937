@import "../../variables";

.nav-social {
    display: inline-flex;

    ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul {
        display: flex;

        li {
            position: relative;
            // padding-right: 0.5rem;
            margin-right: 1rem;

            [dir="rtl"] & {
                margin-right: 1rem;
                margin-left: initial;
            }

            @media screen and (min-width: $breakpoint-lg) {
                margin-right: 1.5rem;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                [dir="rtl"] & {
                    margin-left: 0;
                    padding-left: 0;
                    margin-right: initial;
                    padding-right: initial;
                }
            }
        }
    }

    a, .btn {
        padding: 0;
        min-height: unset;
        color: currentColor;
        line-height: 1;
        transition: 0.2s;
        transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);

        &:hover {
            opacity: 0.5;
        }
    }

    .icon {
        font-size: 28px;
        line-height: 1;
    }
}
