// Variables


// Toggle global options
$enable-gradients: false;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
$body-color: #333;
$body-bg: #C9C9C9;
$border-radius: .4rem;
$success: #7952b3;

// theme-color-variables
$primary: #24272A;
$secondary: #C9C9C9;
$primary-rgb: 36, 39, 42;

$white: #FFFFFF;
$white-primary: #ffeff5;
$white-transp-1: rgba(white, 0.7);
$white-transp-2: rgba(white, 0.3);

$black: #1d1d1d;
$black-primary: #272123;
$text-black: #2d2d2d;
$text-white: #ffffff;
$gray: #8b8b8b;
$red: #C35131;
$tan: #E0D8CB;
$tan2: #F4EFE7;
$dark-green: #343B2D;


// a variable map in Sass
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "white": $white,
    "black": $black,
);


// FONT FAMILY
// sans
$font-family-sans-serif-en:
    TheSans, // Rowad Business Holder Font
    "Segoe UI", // Windows
    -apple-system, // Safari for macOS and iOS (San Francisco)
    system-ui, // Cross-platform generic font family (default user interface font)
    BlinkMacSystemFont, // Chrome < 56 for macOS (San Francisco)
    Roboto, // Android
    Arial, // Basic web fallback
    "Noto Sans", // Linux
    sans-serif, // Sans serif fallback
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default; // Emoji fonts

$font-family-sans-serif-ar: // Add here customize webfont
    "TheSansArabic",
    "Helvetica",
    "Opensans",
        // Safari for OS X and iOS (San Francisco)
    -apple-system,
        // Chrome >= 56 for OS X (San Francisco), Windows, Linux and Android
    system-ui,
        // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    sans-serif;

// serif
$font-family-serif: "Times New Roman", serif;
$serif-article: "Times New Roman", serif;

// monospace
$font-family-monospace: "SFMono-Regular",
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace;

// FONT SIZE
$text-size: 1rem;
$text-sm: 0.875rem;
$text-md: 1rem;
$text-lg: 1.5rem;
$text-xl: 2rem;
$text-xxl: 3rem;

// DEVICE BREAKPOINT SIZE
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px; // 62rem = 992px old : 1024
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px; //1600

$grid-breakpoints: (
    xs: $breakpoint-xs,
    sm: $breakpoint-sm,
    md: $breakpoint-md,
    lg: $breakpoint-lg,
    xl: $breakpoint-xl,
    xxl: $breakpoint-xxl
) !default;


// CONTENT WIDTH
$contentWidthSmall: 40rem;
$contentWidthMedium: 48rem;
$contentWidthLarge: 60rem;
$contentWidthXL: 70rem;

// Page margin
$margin-x-sm: 24px;
$margin-y-sm: 24px;
$margin-x-md: 32px;
$margin-y-md: 32px;
$margin-x-lg: 64px;
$margin-y-lg: 40px;
$margin-x: 64px;
$margin-y: 40px;
$sectionMarginLg: 8rem;
$blockPaddingLg: 6rem;
