@import "../../variables";

.btn-full {
    width: 100%;
}

.btn-solid {
    border: none;
    background: $primary;
    color: $white;
    box-shadow: none;
    min-height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 0.75rem 1.875rem;
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        // width: 0%;
        background: currentColor;
        transition: 0.2s;
        opacity: 0.15;
        border-radius: inherit;
        transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);
    }

    &:hover {
        // opacity: 0.75;
        background: $primary;
        background: var(--primary);

        &::before {
            right: 0;
        }
    }
}

.btn-solid.btn-primary {
    // background: $primary;
    // background: var(--primary);
    // color: $white;
    // &:hover{
    //     background: var(--black-primary);
    //     background: darken($primary, 10);
    //     color: $white;
    // }
}

.btn-solid.btn-white {
    background: $white;
    color: $primary;

    &:hover {
        // opacity: 0.6;
    }
}

.btn-solid.btn-transparent {
    background: none;
    color: currentColor;
}
