@import "../../variables";

.btn-outline-b {
    $borderWidth: 2px;
    $btnHeight: 3.5em;
    // border: $borderWidth solid currentColor;
    border: none;
    border-radius: 160rem;
    background: none;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.3s;
    height: $btnHeight;
    font-size: 1rem;
    line-height: 1;
    padding: 0.75em 0 0.75em 1.5em;
    color: $white;

    [dir="rtl"] & {
        padding: 0.75em 1.5em 0.75em 0;
    }

    .text {
        z-index: 1;
    }

    .icon {
        line-height: 0;
        height: 0.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        > * {
            font-size: 1.2em;
        }
    }

    .icon ~ .text {
        margin-left: 1em;
        [dir="rtl"] & {
            margin-right: 1em;
            margin-left: initial;
        }
    }

    .text ~ .icon {
        margin-left: 1em;
        [dir="rtl"] & {
            margin-right: 1em;
            margin-left: initial;
        }
    }

    &.border-thick {
        border-width: 3px;

        &:before,
        &:after {
            border-width: 3px;
        }
    }

    &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        width: $btnHeight;
        // width: 0%;
        border: $borderWidth solid currentColor;
        border-right-color: transparent;
        opacity: 0.2;
        border-radius: inherit;
        $second: 0.2s;
        $third: 0.2s;
        transition: transform 0s ease-in 0.0s, border $second ease-in 0.0s, width $third ease-in $second;

        [dir="rtl"] & {
            right: 0;
            left: initial;
            border-left-color: transparent;
            border-right-color: initial;
        }
    }

    &:active {
        opacity: 0.75;
    }

    &:hover {
        color: currentColor;
        padding: 0.75em 1.875em;
        background-color: transparent;

        &::before {
            // right: -$borderWidth;
            width: 100%;
            border-right-color: currentColor;
            $first: 0.3s;
            $second: 0.2s;
            $third: 0.2s;
            transform: rotate(360deg);
            transition: transform $first ease-in 0.0s, border $second ease-in $first, width $third ease-in $first;

            [dir="rtl"] & {
                border-left-color: currentColor;
                border-right-color: initial;
                transform: rotate(-360deg);
            }
        }
    }

}

// mixin
@mixin btn-outline-b($color) {
    // color: $color;
    // box-shadow: inset 0 0 0px 2px rgba($color, 0.2);
    // &:hover{
    //     box-shadow: inset 0 0 0px 2px $color;
    // }
}


// color variation
@each $color,
$value in $theme-colors {
    @if $value == $white {
        .btn-outline-b.btn-white {
            // @include btn-outline($value, $primary);
            @include btn-outline-b($value);
        }
    } @else {
        .btn-outline-b.btn-#{""+$color} {
            @include btn-outline-b($value);
        }
    }
}
