
.icon-arrow-a {
    .arrow-left {
        position: relative;
        height: 1em;
        width: 1.25em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0;
            width: 1.25em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            left: 0%;
            top: 0.5em;
            border-top: none;
            border-right: none;
        }

        &:after {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            left: 5%;
            top: 0.325em;
            border-top: none;
            border-right: none;
        }
    }

    .arrow-right {
        position: relative;
        height: 1em;
        width: 1.25em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0;
            width: 1.25em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            right: 0%;
            left: auto;
            top: 0.5em;
            border-top: none;
            border-left: none;
        }

        &:after {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            right: 5%;
            left: auto;
            top: 0.3em;
            border-bottom: none;
            border-left: none;
        }
    }

    .arrow-up {
        position: relative;
        height: 1em;
        width: 1.25em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0;
            width: 1.25em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            width: 0;
            height: 1.25em;
            right: auto;
            left: 0.225em;
            top: 0em;
            border-bottom: none;
            border-right: none;
        }

        &:after {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            right: auto;
            left: 0;
            top: 0;
            border-bottom: none;
            border-right: none;
        }
    }

    .arrow-down {
        position: relative;
        height: 1em;
        width: 1.25em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;
        width: 0.625em;
        margin-top: -4px;

        &:before {
            position: absolute;
            content: "";
            height: 0;
            width: 1.25em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            width: 0;
            height: 1.25em;
            left: auto;
            right: 0.2em;
            top: 0em;
            border-top: none;
            border-left: none;
        }

        &:after {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            right: 0;
            left: auto;
            top: 0.75em;
            border-left: none;
            border-top: none;
        }
    }
}

.btn {
    &:hover {
        .icon-arrow-a.icon-anim {
            .arrow-left {
                &:before {
                    height: 0.5em;
                    width: 0.5em;
                    left: 50%;
                    top: 0.325em;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            .arrow-right {
                &:before {
                    height: 0.5em;
                    width: 0.5em;
                    right: 50%;
                    top: 0.325em;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }

            .arrow-up {
                -webkit-transform: translateY(0.16667em);
                transform: translateY(0.16667em);

                &:before {
                    height: 0.5em;
                    width: 0.5em;
                    left: 0;
                    top: 0.6em;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            .arrow-down {
                -webkit-transform: translateY(-0.25em);
                transform: translateY(-0.25em);

                &:before {
                    height: 0.5em;
                    width: 0.5em;
                    right: 0;
                    top: 0.2em;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .icon-arrow-b.icon-anim {
            .arrow-left {
                &::before {
                    opacity: 0.5;
                }

                &::after {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                    opacity: 1;
                }
            }

            .arrow-right {
                &::before {
                    opacity: 0.5;
                }

                &::after {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                    opacity: 1;
                }
            }
        }
    }
}

.icon-arrow-b {
    .arrow-left {
        position: relative;
        height: 2em;
        width: 2em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            left: 50%;
            top: 50%;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-30%) rotate(45deg);
            transform: translateY(-50%) translateX(-30%) rotate(45deg);
            border-top: none;
            border-right: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid currentColor;
            border-radius: 2em;
            transition: 0.3s;
            opacity: 0.1;
        }
    }

    .arrow-right {
        position: relative;
        height: 2em;
        width: 2em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            left: 50%;
            top: 50%;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-70%) rotate(-45deg);
            transform: translateY(-50%) translateX(-70%) rotate(-45deg);
            border-top: none;
            border-left: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid currentColor;
            border-radius: 2em;
            transition: 0.3s;
            opacity: 0.1;
        }
    }

    .arrow-up {
        position: relative;
        height: 2em;
        width: 2em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            left: 50%;
            top: 50%;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid currentColor;
            border-radius: 2em;
            transition: 0.3s;
            opacity: 0.1;
        }
    }

    .arrow-down {
        position: relative;
        height: 2em;
        width: 2em;
        display: inline-block;
        border-radius: 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:before {
            position: absolute;
            content: "";
            height: 0.5em;
            width: 0.5em;
            left: 50%;
            top: 50%;
            transition: 0.3s;
            border: 1.5px solid currentColor;
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid currentColor;
            border-radius: 2em;
            transition: 0.3s;
            opacity: 0.1;
        }
    }
}
