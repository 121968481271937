.icon-sm {
    font-size: 1.25em;
    line-height: 0.75;
    vertical-align: text-bottom;
}

.icon-md {
    font-size: 32px;
    line-height: 1;
}

.icon-lg {
    font-size: 40px;
}

.icon-box-md {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    min-height: 48px;
}

.icon-box-lg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    min-height: 64px;
}

.icon-img-md {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.icon-img-lg {
    width: 64px;
    height: 64px;
    object-fit: cover;
}
