/* Main SCSS file */
@import "bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import '../js/vendor/fullPage.js-4.0.19/src/css/fullpage.css';
@import "theme";

.slide {
    display: grid;
    align-items: center;
    align-content: center;
    height: 91vh;
    @media (max-width: $breakpoint-md) {
        margin-top: 40px;
    }
    //height: calc(100vh - 384px);
    //@media (max-width: $breakpoint-sm) {
    //    height: calc(100vh - 92px);
    //}
    //@media (max-width: $breakpoint-md) {
    //    height: calc(100vh - 175px);
    //}
    //
    //@media (max-height: $breakpoint-md) {
    //    height: calc(100vh - 175px);
    //}
}
