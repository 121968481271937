@import "../../variables";

.btn-fab {
    display: flex;
    width: 3.5rem;
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    font-size: 1.3125rem;
    border: none;
    background: $primary;
    color: $white;
    position: relative;

    &:before {
        position: absolute;
        z-index: 0;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: currentColor;
        opacity: 0.25;
        border-radius: 2rem;
        transform: scale(0);
        transition: 0.2s;
        transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);
    }

    .icon {
        z-index: 1;
        position: relative;
        line-height: 1;
    }

    &:focus,
    &:hover {
        background: $primary;
        outline: none;

        &:before {
            transform: scale(1.02);
        }
    }

    &.btn-white {
        background: $white;
        color: $primary;
        // overflow: hidden;
        &:before {
            background: $primary;
            opacity: 1;
        }

        &:focus,
        &:hover {
            color: $white;

            &:before {
            }
        }
    }
}
