@import "../../variables";

header {
    padding: 8px $margin-x-sm;
    position: fixed;
    z-index: 10;
    width: 100%;
    transition: 0.6s;
    transform: none;
    opacity: 1;
    visibility: visible;

    @media screen and (min-width: $breakpoint-xs) {
        padding: 0 15px;
        //padding: 0 var(--margin-x-md);
    }

    @media screen and (min-width: $breakpoint-md) {
        padding: 16px $margin-x-md;
    }

    @media screen and (min-width: $breakpoint-lg) {
        padding: 0 $margin-x;
        margin-top: ($margin-y - 8px);
    }

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(6px);
        opacity: 0;
        transition: 0.3s;
        background: rgba(black, 0.2);

        @media screen and (max-width: $breakpoint-md) {
            opacity: 1;
        }
    }

    .navbar-brand {
        display: flex;
        padding: 0;
        margin: 0;
        color: currentColor;

        .logo svg {
            height: 100px;
            width: 50px;
            fill: currentColor;
            transition: 0.6s;

            @media screen and (max-width: 389px) {
                height: 70px;
                width: 35px;
            }
        }

        .text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: currentColor;
            font-size: 1.125rem;

            @media screen and (max-width: 389px) {
                font-size: .900rem;
            }

            .line {
                display: block;
                transition: 0.6s;

                &.sub {
                    font-size: .950rem;
                    @media screen and (max-width: 389px) {
                        font-size: .750rem;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .navbar-toggler {
        color: currentColor;
        border: none;
        height: 20px;
        width: 25px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 100;

        position: relative;

        &:focus,
        &:hover {
            box-shadow: none;
        }

        @media screen and (min-width: $breakpoint-lg) {
            justify-content: flex-end;
            box-sizing: border-box;
            padding-right: 0;

            [dir="rtl"] & {
                padding-left: 0;
                padding-right: initial;
            }
        }
    }

    .navbar {
        .btn {
            font-size: 1rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

            @media screen and (min-width: $breakpoint-lg) {
                font-size: 1rem;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .navbar-collapse {
        display: block;

        .navbar-menu-wrapper {
            padding: $margin-x-sm;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            background: rgba(black, 0.2);
            backdrop-filter: blur(10px);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            //height: 100vh;
            //height: calc(var(--vh, 1vh) * 100);
            //height: 100%;
            z-index: -1;
            overflow-y: auto;
            transition: 0.45s;
            transition-delay: 0s;
            transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);

            @media screen and (min-width: $breakpoint-md) {
                padding: $margin-x-md;
            }

            @media screen and (min-width: $breakpoint-lg) {
                justify-content: flex-end;
                background: transparent;
                padding: 0;
                position: relative;
                z-index: 0;
                flex-direction: row;
                overflow: initial;
                backdrop-filter: initial;
                height: auto;
            }

            .nav-body {
                display: flex;
                position: relative;
                width: 100%;

                @media screen and (min-width: $breakpoint-lg) {
                    justify-content: flex-end;
                    align-items: center;
                }

                .navbar-nav {
                    width: 100%;
                    text-align: left;

                    [dir="rtl"] & {
                        text-align: right;
                    }

                    @media screen and (min-width: $breakpoint-lg) {
                        width: initial;
                    }

                    .nav-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: calc(1.375rem + 5vw);
                        position: relative;

                        @media screen and (min-width: $breakpoint-lg) {
                            font-size: 1rem;
                            margin-left: 24px;
                            margin-right: 24px;
                        }

                        &:first-child {
                            margin-left: 0;

                            [dir="rtl"] & {
                                margin-right: 0;
                                margin-left: 24px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;

                            [dir="rtl"] & {
                                margin-left: 0;
                                margin-right: 24px;
                            }
                        }

                        .nav-link {
                            color: currentColor;
                            padding-left: 0;
                            padding-right: 0;

                            &.dropdown-toggle {
                                padding-right: 2em !important;

                                [dir="rtl"] & {
                                    padding-left: 2em !important;
                                    padding-right: initial;
                                }
                            }

                            &:hover {
                                color: currentColor;
                            }
                        }

                        &.active {
                            .nav-link {
                                color: currentColor;
                                font-weight: bold;
                            }

                            @media screen and (min-width: $breakpoint-lg) {
                                &::before {
                                    width: 100%;
                                    visibility: visible;
                                }
                            }
                        }

                        @media screen and (min-width: $breakpoint-lg) {
                            &:before {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 0;
                                border-bottom: 2px solid currentColor;
                                visibility: hidden;
                                transition: 0.3s;
                                -webkit-transform: translateY(-50%);
                                transform: translateY(-50%);

                                [dir="rtl"] & {
                                    right: 0;
                                    left: initial;
                                }
                            }
                        }

                        &:hover,
                        &:focus {
                            color: currentColor;
                            &::before {
                                width: 100%;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            .nav-footer {
                width: 100%;
                position: relative;
                align-self: flex-end;

                @media screen and (min-width: $breakpoint-lg) {
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .navbar-collapse.collapsing {
        transition: 0s;
        display: block;
        height: 100% !important;
    }

    .navbar-collapse.collapse {
        display: block;
        overflow-x: hidden;

        @media screen and (min-width: $breakpoint-lg) {
            overflow: initial;
        }

        .navbar-menu-wrapper {
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            transform: translateX(40px);

            @media screen and (min-width: $breakpoint-lg) {
                opacity: 1;
                visibility: visible;
                transform: none;
            }
        }

        &.show {
            transition: 0.3s;

            .navbar-menu-wrapper {
                opacity: 1;
                visibility: visible;
                transform: none;
            }

            .navbar-nav {
                .nav-item {
                    &:first-child {
                        margin-left: 0;

                        [dir="rtl"] & {
                            margin-right: 0;
                            margin-left: 0;
                        }
                    }

                    &:last-child {
                        margin-left: 0;
                        margin-right: 0;

                        [dir="rtl"] & {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    &.hide-header {
        transform: translateY(-100%);

        @media screen and (min-width: $breakpoint-md) {
            transform: none;
        }
    }

    .nav-cta {
        @media screen and (min-width: $breakpoint-lg) {
            margin-left: 2.5rem;
            [dir="rtl"] & {
                margin-right: 2.5rem !important;
                margin-left: initial;
            }
        }
    }
}

body.menu-visible {
    header.hide-header {
        transform: none;
    }
}

body.scrolled {
    & header {
        &:before {
            @media screen and (max-width: $breakpoint-lg) {
                opacity: 1;
            }
        }
    }
}
