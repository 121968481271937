@import "../../variables";

#fp-nav {
    @media screen and (max-width: 1024px) {
        visibility: hidden;
    }
}

#fp-nav.right {
    right: $margin-x;
}

.fp-slidesNav {
    &.fp-bottom {
        @media screen and (min-width: $breakpoint-xs) {
            bottom: 10px;
        }
        @media screen and (min-width: $breakpoint-md) {
            bottom: calc($margin-y + 10px);
        }
        @media screen and (min-width: $breakpoint-lg) {
            bottom: $margin-y;
        }
    }
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    background: #fff;
    width: 0.5rem;
    height: 0.5rem;
    margin: -2px 0px 0px -4px;

    [dir="rtl"] & {
        margin: -2px -4px 0px 0px;
    }
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
    width: 0.5rem;
    height: 0.5rem;
    margin: -2px 0px 0px -4px;

    [dir="rtl"] & {
        margin: -2px -4px 0px 0px;
    }
}

.fp-slidesNav ul li a span,
.fp-slidesNav ul li:hover a span {
    margin: -4px 0px 0px -4px;

    [dir="rtl"] & {
        margin: -4px -4px 0px 0px;
    }
}

.fp-slidesNav {
    left: auto!important;
    right: auto!important;
}

#fp-nav span:after,
.fp-slidesNav span:after
{
    background: rgba(255, 255, 255, 0.15);
    position: absolute;
    content: '';
    display: block;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 40px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    [dir="rtl"] & {
        right: 0px;
        left: initial;
    }
}

#fp-nav li .active span,
.fp-slidesNav .active span
{
    background: #fff;
    position: absolute;
}

$border-margin: -4px;
#fp-nav li .active span:after,
.fp-slidesNav .active span:after
{
    top: $border-margin;
    left: $border-margin;
    bottom: $border-margin;
    right: $border-margin;
    border-color: $white;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span
{
    height: 8px;
    width: 8px;
    margin: -4px 0px 0px -4px;
    border-radius: 100%;

    [dir="rtl"] & {
        margin: -4px -4px 0px 0px;
    }
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
    opacity: 0.2;

    &:hover {
        opacity: 0.6;
    }

    &.active,
    &.active:hover {
        opacity: 1;
    }
}

#fp-nav ul li .fp-tooltip {
    font-family: $font-family-sans-serif-en;
    [dir="rtl"] & {
        font-family: $font-family-sans-serif-ar;
    }
}
