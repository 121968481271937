.section-contact {
    .contact-main,
    .email-main {
        font-size: 1.5rem;
        color: $white;
        text-decoration: none;
        transition: 0.2s;
        transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);

        &:hover {
            opacity: 0.5;
        }

        @media screen and (min-width: $breakpoint-md) {
            font-size: 2.5rem;
        }
    }

    .dep-phone {
        font-size: 1.3rem;
        color: $white;
        text-decoration: none;
        transition: 0.2s;
        transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);

        &:hover {
            opacity: 0.5;
        }
    }

    .dep-name {
        font-size: 1.3rem;
        @media screen and (min-width: $breakpoint-md) {
            font-size: 1.5rem;
        }
    }

    .dep-address {
        @include text-p();
    }
}
