@import "../variables";

:lang(en) {
    font-family: $font-family-sans-serif-en;
}

:lang(ar) {
    font-family: $font-family-sans-serif-ar;
}

.ltr {
    direction: ltr;
    text-align: left;
}

.rtl {
    direction: rtl;
    text-align: right;
}

html{
    font-size: 16px;
}

body {
    color: $secondary;
    background: $primary;
}
