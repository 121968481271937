@import "../variables";

$duration: 6s;
$duration2: 2s;
$delay1: .5s;
$delay2: $duration + $delay1 - 1;
$delay3: $duration + $delay2 - 1;
$delay4: $duration + $delay3 - 1;
$delay5: $duration + $delay4 - 1;
$delay6: $duration + $delay5 - 1;
$delay7: $duration + $delay6 - 3;

.page-cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    // height: calc(var(--vh,1vh)*100);
    height: 100vh;
    transition: 0.3s;

    .cover-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .cover-bg-color {
        --bs-bg-opacity: 1;
        background-color: rgba($primary-rgb, var(--bs-bg-opacity)) !important;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: kenburns-top $duration linear both reverse $delay1, fade-out $duration2 ease-in forwards $delay2;
    }

    iframe,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@keyframes kenburns-top {
    0% {
        transform: scale(1) translateY(0);
        transform-origin: 50% 16%;
    }
    100% {
        transform: scale(1.25) translateY(-15px);
        transform-origin: top;
    }
}


