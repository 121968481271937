.modal-a {

    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // z-index: 1;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    transition: 0.3s;
    // background: rgba(mix($primary, $black, 0.5), 0.75);
    // background: rgba($black, 0.2);
    display: flex !important;
    visibility: hidden;
    opacity: 0;

    .modal-content {
        background: none;
        border: none;
        padding: 0;
    }

    .modal-dialog {
        width: 100%;
        height: fit-content;
        max-width: 360px;
        transform: scale(1.1);
        transition: 0.3s;
        opacity: 0;

        .popup-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border-bottom: 2px solid rgba(white, 0.2);
            margin-bottom: 1rem;

            .title {
                font-size: 2rem;
                font-weight: bold;
            }
        }

        .popup-body {
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding: 1rem;
            border-radius: 1rem;
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;

        .modal-dialog {
            transform: none;
            transition: 0.3s;
            opacity: 1;
        }
    }
}
