@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("TheSansArabic-ExtraLight.woff2") format("woff2"),
    url("TheSansArabic-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("TheSansArabic-Light.woff2") format("woff2"),
    url("TheSansArabic-Light.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 350;
    font-display: swap;
    src: url("TheSansArabic-SemiLight.woff2") format("woff2"),
    url("TheSansArabic-SemiLight.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("TheSansArabic-Plain.woff2") format("woff2"),
    url("TheSansArabic-Plain.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("TheSansArabic-SemiBold.woff2") format("woff2"),
    url("TheSansArabic-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("TheSansArabic-Bold.woff2") format("woff2"),
    url("TheSansArabic-Bold.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("TheSansArabic-ExtraBold.woff2") format("woff2"),
    url("TheSansArabic-ExtraBold.woff") format("woff");
}

@font-face {
    font-family: "TheSansArabic";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("TheSansArabic-Black.woff2") format("woff2"),
    url("TheSansArabic-Black.woff") format("woff");
}
