@import 'control';

.form-a {
    .info {
        .success {
            display: none;
        }

        .error {
            display: none;
        }
    }

    .success-show {
        display: none;
    }

    .error-show {
        display: none;
    }

    &.form-success {
        .success-gone {
            display: none;
        }

        .success-show {
            display: block;
            color: #28a745;
        }
    }

    &.form-error {
        .error-show {
            display: block;
            color: #dc3545;
        }
    }

    label {
        [dir="rtl"] & {
            float: right;
        }
    }
}
