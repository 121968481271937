@import "../variables";

.section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    // width: 100%;

    .container{
        //background: rgba(0, 0, 0, 0.1);
    }

    &.with-overflow {
        overflow: initial;
    }

    // overflow-x: hidden;
    &.child-sticky {
        overflow: initial;
    }

    .title-desc {
        p {
            @include text-p('currentColor');
        }
    }

    .title-desc.large-text-size {
        .text-p,
        p {
            @include text-p-large(currentColor);
        }
    }

    .title-desc.xl-text-size {
        .text-p,
        p {
            @include text-p-xl(currentColor);
            font-weight: 300;

            strong,
            .strong {
                font-weight: 600;
                // color: $white;
            }
        }
    }

    .section-body {
        position: relative;
        width: 100%;
        padding-top: 80px;
        padding-bottom: 80px;
        @media screen and (min-width: $breakpoint-lg) {
            padding-top: 9rem;
            padding-bottom: 9rem;
        }
    }

    &.section-pt-0 {
        .section-body {
            padding-top: 0;
            @media screen and (min-width: $breakpoint-lg) {
                padding-top: 0;
            }
        }
    }

    &.section-pb-0 {
        .section-body {
            padding-bottom: 0;
            @media screen and (min-width: $breakpoint-lg) {
                padding-bottom: 0;
            }
        }
    }
}
