
@use "sass:selector";

// Variables
@import 'variables';
@import 'functions';

$black: #1d1d1d;
$white: #FFFFFF;
$text-black: #2d2d2d;
$text-white: #ffffff;

:root {
    --margin-x: 60px;
    --margin-y: 60px;
    --white: #{$white};
    --black: #{$black};
    --text-white: #{$text-white};
    --text-black: #{$text-black};
}

body {

    & .content-white {
        color: set-text-color($secondary);
    }

    &.content-white {
        color: $secondary;
        background-color: $primary;

        .btn-outline {
            @include button-outline-variant($secondary, #222222, lighten($secondary,5%), $secondary);
        }

        .navbar {
            color: $secondary;
            .navbar-brand {
                background: transparent;
                color: $secondary;
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:focus,
                        &:hover {
                            color: rgba($secondary, 0.7)!important;
                        }
                    }

                    .btn-solid.btn-transparent {
                        background: none;
                        color: currentColor;

                        &:focus,
                        &:hover {
                            color: rgba($secondary, 0.7)!important;
                        }
                    }
                }

                .nav-footer {
                    .btn {
                        color:$secondary;
                    }
                }
            }
        }

        #fp-nav ul li .fp-tooltip {
            color: $secondary;
        }
    }

    & .content-dark {
        color: set-text-color($primary);
        background-color: $secondary;
    }

    &.content-dark {
        color: $primary;
        background-color: $secondary;

        .navbar {
            color: $primary;
            .navbar-brand {
                background: transparent;
                color: $primary;
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        &:focus,
                        &:hover {
                            color: rgba($primary, 0.7)!important;
                        }
                    }
                }
                .btn-solid.btn-transparent {
                    background: none;
                    color: currentColor;

                    &:focus,
                    &:hover {
                        color: rgba($primary, 0.7)!important;
                    }
                }
            }
        }

        #fp-nav ul li a span,
        .fp-slidesNav ul li a span {
            background: $primary;
            &:after {
                background: rgba($primary, 0.2);
            }

            :hover {
                color: $primary!important;
            }
        }

        .section p {
            color: rgba($primary, 0.7);
        }

        #fp-nav ul li .fp-tooltip {
            color: $primary;
        }




        //.navbar-collapse {
        //    .navbar-nav {
        //        .nav-item {
        //            .nav-link {
        //                color: #C0105D!important;
        //            }
        //
        //            &.active {
        //                .nav-link {
        //                    color: #8b8b8b!important;
        //                }
        //            }
        //        }
        //    }
        //}
    }

    //.text-stroke {
    //    -webkit-text-stroke: 1px $text-black;
    //    -webkit-text-stroke: 1px var(--text-black, $text-black);
    //    color: transparent;
    //}
    //
    //.content-white {
    //    color: $text-white;
    //
    //    .navbar-brand {
    //        background: transparent;
    //        color: $text-white;
    //
    //        .logo {
    //            color: #dbdbdc;
    //            fill: currentColor;
    //        }
    //    }
    //
    //    .navbar-nav {
    //        .nav-link {
    //            color: rgba($white, 0.7);
    //            padding-left: 0;
    //            padding-right: 0;
    //
    //            &:hover,
    //            &:focus {
    //                color: $white;
    //            }
    //        }
    //
    //        .nav-item.active a,
    //        .nav-item.active .nav-item {
    //            color: $white;
    //        }
    //    }
    //
    //    .navbar-toggler {
    //        color: $white;
    //    }
    //
    //    .dropdown-menu {
    //        box-shadow: 0 0 8px rgba(white, 0.1);
    //        background: rgba(black, 0.5);
    //    }
    //
    //    .dropdown-toggle::before {
    //        box-shadow: inset 0 0 0 1px rgba(white, 0.2);
    //    }
    //
    //    .dropdown-toggle:after {
    //        background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228.628%22%20height%3D%225.137%22%20viewBox%3D%220%200%208.628%205.137%22%3E%0D%0A%20%20%3Cpath%20id%3D%22chevron-down-sharp%22%20d%3D%22M9.193,12.87l3.491-3.491.823.823L9.193,14.516,4.879,10.2,5.7,9.379Z%22%20transform%3D%22translate(-4.879%20-9.379)%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A');
    //        background-position: center 55%;
    //        background-repeat: no-repeat;
    //        background-size: 10px;
    //    }
    //
    //    .btn-outline,
    //    .btn-outline.btn-white {
    //        color: $secondary;
    //        //box-shadow: inset 0 0 0 2px rgba(white, 0.2);
    //    }
    //
    //    .btn-outline .text:hover {
    //        color: $primary;
    //    }
    //
    //    .page-footer {
    //        color: $primary;
    //
    //        .note {
    //            color: rgba($secondary, 0.5);
    //
    //            a {
    //                color: $secondary;
    //            }
    //        }
    //    }
    //
    //    .text-stroke {
    //        -webkit-text-stroke: 1px white;
    //        color: transparent;
    //    }
    //
    //    .title-desc {
    //        color: rgba($secondary, 0.7);
    //
    //        p {
    //            @include text-p($secondary);
    //        }
    //    }
    //
    //    .contact-main,
    //    .email-main {
    //        color: $white;
    //    }
    //
    //}
    //
    //.content-dark {
    //
    //    color: $primary;
    //
    //    .navbar-brand {
    //        background: transparent;
    //        color: $primary;
    //
    //        .logo {
    //            color: $primary;
    //            fill: currentColor;
    //        }
    //    }
    //
    //    .navbar-nav {
    //        .nav-link {
    //            color: rgba($primary, 0.7);
    //            padding-left: 0;
    //            padding-right: 0;
    //
    //            &:hover,
    //            &:focus {
    //                color: $primary;
    //            }
    //
    //        }
    //
    //        .nav-item.active a,
    //        .nav-item.active .nav-item {
    //            color: $primary;
    //        }
    //    }
    //
    //    .navbar-toggler {
    //        color: $primary;
    //    }
    //
    //    .dropdown-menu {
    //        box-shadow: 0px 0px 8px rgba(black, 0.1);
    //        background: rgba(black, 0.5);
    //    }
    //
    //    .dropdown-toggle::before {
    //        box-shadow: inset 0 0 0 1px rgba(black, 0.2);
    //    }
    //
    //    .dropdown-toggle:after {
    //        background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228.628%22%20height%3D%225.137%22%20viewBox%3D%220%200%208.628%205.137%22%3E%0D%0A%20%20%3Cpath%20id%3D%22chevron-down-sharp%22%20d%3D%22M9.193,12.87l3.491-3.491.823.823L9.193,14.516,4.879,10.2,5.7,9.379Z%22%20transform%3D%22translate(-4.879%20-9.379)%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A');
    //        background-position: center 55%;
    //        background-repeat: no-repeat;
    //        background-size: 10px;
    //    }
    //
    //    .btn-outline,
    //    .btn-outline.btn-white {
    //        color: $black;
    //        box-shadow: inset 0 0 0 2px rgba(black, 0.2);
    //    }
    //
    //    #fp-nav ul li a span,
    //    .fp-slidesNav ul li a span {
    //        background: $primary;
    //        &:after {
    //            background: rgba($primary, 0.2);
    //        }
    //    }
    //
    //    .page-footer {
    //        color: $primary;
    //        .note {
    //            color: rgba($primary, 0.5);
    //
    //            a {
    //                color: $primary;
    //            }
    //        }
    //    }
    //
    //    .text-stroke {
    //        -webkit-text-stroke: 1px black;
    //        color: transparent;
    //    }
    //
    //    .title-desc {
    //        color: rgba($primary, 0.7);
    //
    //        p {
    //            @include text-p($primary);
    //        }
    //    }
    //
    //    .contact-main,
    //    .email-main {
    //        color: $primary;
    //    }
    //
    //}
}

.grecaptcha-badge {
    visibility: hidden;
}
