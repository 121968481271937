@import "../../variables";

.btn-scroll-down {
    color: currentColor;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
        margin-bottom: 8px;
    }

    .text {
        font-size: $text-sm;
        transition: 0.3s;
        transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);
    }

    .text-before-vertical {
        display: block;
        font-size: 0.875rem;
        text-align: center;
        margin: 1rem 0;
        perspective: 40px;
        opacity: 0.3;
        position: relative;

        .char {
            display: block;
            height: 1em;
            width: 1em;
            transition: .0s;
            transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);
        }
    }

    // scroll down
    .icon-scroll-down {
        width: 22px;
        height: 38px;
        border: 1px solid rgba(white, 0.3);
        display: block;
        border-radius: 40px;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            top: 8px;
            left: 37%;
            transform: translateX(-50%);
            width: 6px;
            height: 6px;
            border-radius: 16px;
            background: $white;
            //transition: 0.3s;
            //transition-timing-function: cubic-bezier(0.25, 0.75, 0.2, 1);

            -webkit-animation: scroll_down_btn-animation 2s infinite;
            animation: scroll_down_btn-animation 2s infinite;

            [dir="rtl"] & {
                right: 37%;
                left: initial;
            }
        }
    }

    &:hover {
        color: currentColor;

        .text-before-vertical {
            .char {
                transition: 2s;
                transform: rotateY(360deg);
            }

            // opacity
            @for $i from 0 through 20 {
                .char:nth-child(#{$i}) {
                    transition-delay: ($i - 1) * 0.075s;
                }
            }

        }

        .icon-scroll-down {
            &:after {
                //top: 22px;
            }
        }

        .text {
            opacity: 0.5;
        }
    }

}


@keyframes scroll_down_btn-animation {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes scroll_down_btn-animation {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
