.btn-inline {
    @include btn-inline();
}

.btn-line-a {
    color: currentColor;
    border: none;
    background: none;
    padding: 0.75em 0;
    display: inline-flex;
    align-items: center;
    transition: 0.3s;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0;
    padding: 0;
    padding-top: 8px;
}

.btn-line-a .text {
    position: relative;
    display: inline-block;
}

.btn-line-a .text::before {
    z-index: 1;
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
    width: 100%;
    border-top: 1px solid currentColor;

    [dir="rtl"] & {
        right: 0;
        left: initial;
    }
}

.btn-line-a .icon {
    display: inline-flex;
}

.btn-line-a .icon ~ .text {
    /*rtl:ignore*/
    margin-left: 1em;
    margin-right: 1em;
}

.btn-line-a .text ~ .icon {
    /*rtl:ignore*/
    margin-left: 1em;
    margin-right: 1em;
}

.btn-line-a:active, .btn-line-a:hover {
    color: currentColor;
    background: none;
    box-shadow: none;
    opacity: 0.7;
}

.btn-line-a:active::before, .btn-line-a:hover::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
    width: 1rem;

    [dir="rtl"] & {
        transform: rotate(45deg);
    }
}

.btn-line-a:active::after, .btn-line-a:hover::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
    width: 1rem;

    [dir="rtl"] & {
        transform: rotate(-45deg);
    }
}
