@import "../variables";

.page-footer {
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.3s;
    position: relative;
    padding: $margin-x-sm;
    z-index: 5;
    pointer-events: none;

    > * {
        pointer-events: visible;
    }

    @media screen and (min-width: $breakpoint-md) {
        left: $margin-x-md;
        right: $margin-x-md;
        bottom: $margin-y-md;
        position: absolute;
    }

    @media screen and (min-width: $breakpoint-lg) {
        left: $margin-x;
        right: $margin-x;
        bottom: $margin-y;
        position: fixed;
    }

    .footer-left {
        position: relative;
        left: 0;
        bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
            position: absolute;
        }
    }

    .footer-right {
        position: relative;
        right: 0;
        bottom: 0;
        margin-top: 1rem;
        @media screen and (min-width: $breakpoint-md) {
            position: absolute;
        }
    }

    .note {
        color: currentColor; // rgba($white, 0.5);
        margin-bottom: 0;

        .marked {
            font-weight: 500;
        }

        a {
            text-decoration: none;
            color: currentColor; // $white;
        }
    }
}
