@import "../../variables";

// icon menu a
.icon-menu {
    $iconWidth: 24px;
    $barWidth: 2.0px;
    $barSpace: 6px;
    color: currentColor;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: transparent;
    border-radius: 2px;
    opacity: 1;

    .text {
        position: absolute;
        text-align: left;
        top: 13px;
        line-height: 1;
        right: 100%;
        padding-right: 8px;
        text-transform: uppercase;
        font-size: 14px;
        color: currentColor;
        transition: 0.3s;

        [dir="rtl"] & {
            right: 0;
            left: initial;
            text-align: right;
            padding-left: 8px;
            padding-right: initial;
        }

        @media (min-width: $breakpoint-md) {
            top: -100%;
            right: auto;
            left: -20%;
            transform: rotate(-90deg);

            [dir="rtl"] & {
                right: -20%;
                left: auto;
                transform: rotate(90deg);
            }
        }

        &.hide-menu-visible {
            visibility: visible;
            opacity: 1;
            top: -100%;
        }

        &.show-menu-visible {
            visibility: hidden;
            opacity: 0;
            top: -200%;
        }

    }

    .bars {
        width: $iconWidth;
        display: flex;
        flex-direction: column;
        position: relative;
        transform: translateY(-$barWidth, 2);
    }

    .bar1,
    .bar2,
    .bar3 {
        display: flex;
        height: $barWidth;
        background: currentColor;
        width: 100%;
        transition: 0.3s;
        position: absolute;
        top: 50%;
    }

    .bar1 {
        transform: translateY(-$barSpace - $barWidth);
    }

    .bar3 {
        transform: translateY($barSpace + $barWidth);
    }

    &.bars-spaced {
        .bar1 {
            transform: translateY((-$barSpace - $barWidth)*2);
        }

        .bar3 {
            transform: translateY(($barSpace + $barWidth)*2);
        }
    }
}

.navbar-toggler[aria-expanded="true"] .icon-menu,
.icon-menu.menu-visible {
    .text {
        &.show-menu-visible {
            visibility: visible;
            opacity: 1;
            top: -100%;
        }

        &.hide-menu-visible {
            visibility: hidden;
            opacity: 0;
            top: -200%;
        }
    }

    .bar1 {
        width: 100%;
        transform: rotate(45deg) scaleX(0.8);
        transform-origin: center;
    }

    .bar3 {
        width: 100%;
        transform: rotate(-45deg) scaleX(0.8);
        transform-origin: center;
    }

    .bar2 {
        transform: scaleX(0);
    }
}
