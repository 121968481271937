@import "../variables";

.hero {
    --btns-margin-top: 2.5rem;

    @media screen and (min-width: $breakpoint-md) {
        align-items: center;
    }

    .section-body {
        padding-top: 6rem;
        padding-bottom: 6rem;
        @media screen and (min-height: 660px) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }
        @media screen and (min-width: $breakpoint-lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .hero-title {
        font-size: 3.5rem;
        font-weight: 800;
        line-height: 1.1;
        @media screen and (min-width: $breakpoint-md) {
            font-size: 3rem;
            margin-bottom: 1rem;
        }
        @media screen and (min-width: $breakpoint-lg) {
            font-size: 4rem;
        }
    }

    .hero-desc {
        font-size: 1.5rem;
        line-height: 1.625;
        margin-bottom: 0;
    }

    .hero-btns {
        margin-top: 1.5rem;
        width: 100%;
        @media screen and (min-width: $breakpoint-lg) {
            margin-top: 2rem;
            position: absolute;
        }
    }
}
