@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("./TheSans-ExtraLight.woff2") format("woff2"),
    url("./TheSans-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("./TheSans-ExtraLightItalic.woff2") format("woff2"),
    url("./TheSans-ExtraLightItalic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("./TheSans-Light.woff2") format("woff2"),
    url("./TheSans-Light.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("./TheSans-LightItalic.woff2") format("woff2"),
    url("./TheSans-LightItalic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 350;
    font-display: swap;
    src: url("./TheSans-SemiLight.woff2") format("woff2"),
    url("./TheSans-SemiLight.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 350;
    font-display: swap;
    src: url("./TheSans-SemiLightItalic.woff2") format("woff2"),
    url("./TheSans-SemiLightItalic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./TheSans-Plain.woff2") format("woff2"),
    url("./TheSans-Plain.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("./TheSans-Italic.woff2") format("woff2"),
    url("./TheSans-Italic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("./TheSans-SemiBold.woff2") format("woff2"),
    url("./TheSans-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("./TheSans-SemiBoldItalic.woff2") format("woff2"),
    url("./TheSans-SemiBoldItalic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./TheSans-Bold.woff2") format("woff2"),
    url("./TheSans-Bold.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("./TheSans-BoldItalic.woff2") format("woff2"),
    url("./TheSans-BoldItalic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("./TheSans-ExtraBold.woff2") format("woff2"),
    url("./TheSans-ExtraBold.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("./TheSans-ExtraBoldItalic.woff2") format("woff2"),
    url("./TheSans-ExtraBoldItalic.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("./TheSans-Black.woff2") format("woff2"),
    url("./TheSans-Black.woff") format("woff");
}

@font-face {
    font-family: "TheSans";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("./TheSans-BlackItalic.woff2") format("woff2"),
    url("./TheSans-BlackItalic.woff") format("woff");
}
